<template>
  <div class="flex bg-slate-100">
    <button @click="scrollPrev" type="button" class="flex-none hover:text-gold px-3">
      <span class="sr-only">Previous</span>
      <svg class="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M9.78 4.22a.75.75 0 0 1 0 1.06L7.06 8l2.72 2.72a.75.75 0 1 1-1.06 1.06L5.47 8.53a.75.75 0 0 1 0-1.06l3.25-3.25a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" /></svg>
    </button>

    <div ref="emblaRef" class="embla flex-auto max-w-5xl py-6 md:py-8 lg:py-12 mx-auto">
      <div class="embla__container">
        <div v-for="(testimonial, index) in parsedTestimonials" :key="index" class="embla__slide flex flex-col items-center justify-center">
          <img :src="`/assets/5-stars.svg`" alt="5 Star Reviews" class="w-auto h-5 mx-auto">
          <blockquote class="text-lg sm:text-xl/8 font-semibold tracking-tight text-center text-balance mt-3">
            &ldquo; {{ testimonial.col1 }} &rdquo;
          </blockquote>
        </div>
      </div>
    </div>

    <button @click="scrollNext" type="button" class="flex-none hover:text-gold px-3">
      <span class="sr-only">Next</span>
      <svg class="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" /></svg>
    </button>    
  </div>
</template>

<script setup>
import { computed } from 'vue'
import emblaCarouselVue from 'embla-carousel-vue'
import Autoplay from 'embla-carousel-autoplay'

const props = defineProps({
  testimonials: {
    type: String,
    required: true
  }
})

const parsedTestimonials = computed(() => JSON.parse(props.testimonials))
const [emblaRef, emblaApi] = emblaCarouselVue({ loop: true }, [Autoplay({ delay: 8000 })])

const scrollPrev = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollPrev()
  }
}

const scrollNext = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollNext()
  }
}
</script>

<style scoped>
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
</style>
